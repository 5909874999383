<template>
  <div v-loading="loading">
    <div style="display: flex;align-items: center;justify-content: space-between;padding: 30px;">
      <div style="display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">班组名称：</div>
        <div>
          <el-input v-model="query.teamName" style="width: 220px;height: 32px;" placeholder='请输入班组名称' />
        </div>
      </div>
      <div style="display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">工人退场日期：</div>
        <div>
          <el-date-picker v-model="query.getOutTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择工人退场日期" size='large' style="width: 220px;height: 32px;" />
        </div>
      </div>
      <div style="display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);display: flex;align-items: center;">
          <el-button type="primary" size="default" :icon="Search" style="width: 78px;height: 32px;" @click="searchList">搜索</el-button>
          <el-button size="default" :icon="RefreshLeft" style="width: 78px;height: 32px;margin-left:10px;" @click="reset">重置</el-button>
        </div>
      </div>
    </div>
    <div style="display: flex;align-items: center;padding: 20px 30px;">
      <el-upload v-model:file-list="fileList" :on-success="uploadSuccess" :headers="headers" action="/api/blade-team/team/put-file" style="display: flex;">
        <el-button style="width: 70px;height: 40px;color: rgb(31, 112, 247);border: 1px solid rgb(31, 112, 247);margin-right:20px;font-size: 14px;">
          <template #default>
            <img src="./../../static/img01.png" alt="" style="margin-right:5px;">
            导入
          </template>
        </el-button>
      </el-upload>
      <el-button @click="expertTeam" style="margin-right:20px;width: 70px;height: 40px;color: rgb(31, 112, 247);border: 1px solid rgb(31, 112, 247);font-size: 14px;">
        <template #default>
          <img src="./../../static/img02.png" alt="" style="margin-right:5px;">
          导出
        </template>
      </el-button>
      <el-button type="primary" @click="addTeam" class="btn-8">
        <template #default>
          <img src="./../../static/img03.png" alt="" style="margin-right:5px;">
          新增班组
        </template>
      </el-button>
      <el-button @click="batchTeamExit" style="width: 150px;height: 40px;border: 1px solid rgb(31, 112, 247);margin-left:0;font-size: 14px;">
        <template #default>
          <img src="./../../static/img04.png" alt="" style="margin-right:5px;">
          批量退场工人班组
        </template>
      </el-button>
    </div>
    <div style="padding: 20px 30px;">
      <el-table :data="tableData" @selection-change="selectionChange" style="width: 100%;font-size:14px;">
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column prop="teamCode" label="编号" show-overflow-tooltip align="center" />
        <el-table-column prop="teamName" label="班组名称" show-overflow-tooltip align="center" />
        <el-table-column prop="companyName" label="所属企业" show-overflow-tooltip align="center" width="180" />
        <el-table-column prop="projectName" label="所属项目" show-overflow-tooltip align="center" width="200" />
        <el-table-column prop="leader" label="班组长" show-overflow-tooltip align="center" />
        <el-table-column prop="contactsPhone" label="责任人联系电话" show-overflow-tooltip align="center" width="180" />
        <el-table-column prop="contacts" label="责任人姓名" show-overflow-tooltip align="center" width="100" />
        <el-table-column prop="certificatesType" label="责任人证件类型" show-overflow-tooltip align="center" width="120" />
        <el-table-column prop="certificatesNum" label="责任人证件号码" show-overflow-tooltip align="center" width="200" />
        <el-table-column prop="comeInTime" label="进场日期" show-overflow-tooltip align="center" width="180" />
        <el-table-column prop="getOutTime" label="退场日期" show-overflow-tooltip align="center" width="180" />
        <el-table-column prop="payWay" label="工资支付方式" show-overflow-tooltip align="center" width="100" />
        <el-table-column prop="workHourSystem" label="工时制度" show-overflow-tooltip align="center" />
        <el-table-column prop="remarks" label="备注" show-overflow-tooltip align="center" />
        <el-table-column label="操作" fixed="right" min-width="120" align="center">
          <template #default="scope">
            <el-button v-if="scope.row.activeState!=-1" type="text" size="default" @click="teamExit(scope.row)">
              退场
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="padding: 20px 30px;display:flex;justify-content: flex-end;">
      <el-pagination v-model:current-page="page.currentPage" v-model:page-size="page.pageSize" :page-sizes="[10, 20, 40]" :size="size" :disabled="disabled" background layout="total, sizes, prev, pager, next, jumper" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <el-dialog style="margin-top:3vh" v-model="dialogVisible" title="新增班组" width="1050" :before-close="handleClose">
      <el-form ref="ruleFormRef" :inline="true" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon>
        <el-form-item label="编号：" prop="teamCode">
          <el-input v-model="ruleForm.teamCode" disabled style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="班组名称：" prop="teamName">
          <el-input v-model="ruleForm.teamName" placeholder="请输入班组名称" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="所属企业：" prop="companyName">
          <el-input v-model="ruleForm.companyName" placeholder="请输入所属企业" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="所属项目：" prop="projectName">
          <el-input v-model="ruleForm.projectName" type="text" placeholder="请输入所属项目" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="班组长：" prop="leader">
          <el-input v-model="ruleForm.leader" type="text" placeholder="请输入班组长" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="责任人联系电话：" prop="contactsPhone">
          <el-input v-model="ruleForm.contactsPhone" type="text" placeholder="请输入责任人联系电话" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="责任人姓名：" prop="contacts">
          <el-input v-model="ruleForm.contacts" type="text" placeholder="请输入责任人姓名" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="责任人证件类型：" prop="certificatesType">
          <el-input v-model="ruleForm.certificatesType" type="text" placeholder="请输入责任人证件类型" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="责任人证件号码：" prop="certificatesNum">
          <el-input v-model="ruleForm.certificatesNum" type="text" placeholder="请输入责任人证件号码" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="进场日期：" prop="comeInTime">
          <el-input v-model="ruleForm.comeInTime" type="text" placeholder="请输入进场日期" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="退场日期：" prop="getOutTime">
          <el-input v-model="ruleForm.getOutTime" type="text" placeholder="请输入退场日期" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="工资支付方式：" prop="payWay">
          <el-input v-model="ruleForm.payWay" type="text" placeholder="请输入工资支付方式" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="工时制度：" prop="workHourSystem">
          <el-input v-model="ruleForm.workHourSystem" type="text" placeholder="请输入工时制度" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="备注：" prop="remarks">
          <el-input v-model="ruleForm.remarks" type="text" placeholder="请输入备注" style="min-height:40px;" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer" style="display: flex;justify-content: center;align-items: center">
          <el-button type="primary" @click="SuccessDialogVisible(ruleFormRef)" style="margin-right: 10px;width: 100px;height:40px;">
            确定
          </el-button>
          <el-button @click="resetForm(ruleFormRef)" style="width: 100px;height:40px;">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { RefreshLeft, Search } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { teamExitApi, teamBatchExitApi, insertTeamApi, exportBlob } from '@/api/biz/team';
import { getCmsList, } from '@/api/index.js';
import { onMounted, ref } from 'vue';
let headers = { "blade-auth": 'bearer ' + localStorage.getItem('tokenuser') }
let name = ref()
let tableData = ref();
let fileList = ref([]);
let searchIs = ref(false)
let loading = ref(true)
let disabled = ref(false)

let size = ref('default')
let page = ref({
  total: '',
  currentPage: 1,
  pageSize: 10
});
let query = ref({
  getOutTime: '',
  teamName: ''
});
let ids = ref([]);
let ruleFormRef = ref()

let ruleForm = ref({
  teamName: '',
  companyName: '',
  projectName: '',
  leader: '',
  contactsPhone: '',
  contacts: '',
  certificatesType: '',
  certificatesNum: '',
  comeInTime: '',
  getOutTime: '',
  payWay: '',
  workHourSystem: '',
  remarks: '',
})
let rules = ref({
  projectName: [
    { required: true, message: '请输入所属项目', trigger: 'blur' },
  ], teamName: [
    { required: true, message: '请输入班组名称', trigger: 'blur' },
  ],
  leader: [
    { required: true, message: '请输入班组长', trigger: 'blur' },
  ],
})
let dialogVisible = ref(false)
let SuccessDialogVisible = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      insertTeamApi(ruleForm.value).then(res => {
        console.log(res);
        if (res.data.success) {
          ElMessage.success('新增班组成功！');
          resetForm(ruleFormRef.value)
          onLoad()
        } else {
          ElMessage.error(res.data.msg);
        }
      })
    } else {
      console.log('error submit!', fields);
    }
  });
}
let uploadSuccess = (res) => {
  ElMessage({type: 'success',message: '成功导入' + res.data.success + '条'})
  onLoad()
}
let resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  dialogVisible.value = false
}
let handleClose = () => {
  resetForm(ruleFormRef.value)
}
let onLoad = () => {
  getCmsList({ current: page.value.currentPage, pages: page.value.pageSize, teamName: query.value.teamName, getOutTime: query.value.getOutTime }).then(res => {
    tableData.value = res.data.records
    page.value.total = res.data.total
  })
}
let teamExit = (row) => {
  ElMessageBox.confirm('确定退场该班组吗？', '班组退场',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showCancelbutton: true
    }
  ).then(() => {
    teamExitApi({ id: row.id }).then(res => {
      if (res.code == 200) {
        ElMessage({
          type: 'success',
          message: '退场成功',
        })
        onLoad()
      }
    }).catch(() => {
      return
    })
  })
}
let batchTeamExit = () => {
  if (ids.value && ids.value != '') {
    ElMessageBox.alert('确定批量退场该工人吗？', '工人退场',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        teamBatchExitApi(ids.value.toString()).then(res => {
          if (res.code == 200) {
            ElMessage({
              type: 'success',
              message: '批量退场成功',
            })
            onLoad()
          } else {
            ElMessage({
              type: 'warning',
              message: '请稍后再试',
            })
          }
        })
      }).catch(() => {
        return
      })
  } else {
    ElMessage({
      type: 'warning',
      message: '请至少选择一条工人班组数据',
    })
  }
}
let addTeam = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  if (hours < 10) {
    hours = '0' + hours;
    minutes = '0' + minutes;
  }
  ruleForm.value.teamCode = year + month + day + hours + minutes;
  dialogVisible.value = true
}
let expertTeam = () => {
  window.location.href = '/api/blade-team/team/export-team?Blade-Auth=' +
    'bearer ' + localStorage.getItem('tokenuser')
  // exportBlob().then(res => {
  //   downloadXls(res.data, `班组数据表.xlsx`);
  // });
}
let selectionChange = (newSelection) => {
  if (newSelection.length) {
    let tempIds = ref([]);
    newSelection.forEach(e => tempIds.value.push(e.id));
    ids.value = tempIds.value;
  }
}
let downloadXls = (fileArrayBuffer, filename) => {
  let data = new Blob([fileArrayBuffer], { type: 'application/vnd.ms-excel,charset=utf-8' });
  if (typeof window.chrome !== 'undefined') {
    // Chrome
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = filename;
    link.click();
  } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE
    var blob = new Blob([data], { type: 'application/force-download' });
    window.navigator.msSaveBlob(blob, filename);
  } else {
    // Firefox
    var file = new File([data], filename, { type: 'application/force-download' });
    window.open(URL.createObjectURL(file));
  }
};
let handleSizeChange = (val) => {
  page.value.pageSize = val
  if (searchIs.value) {
    onLoad()
  } else {
    query.value.getOutTime = ''
    query.value.teamName = ''
    onLoad()
  }
  window.scrollTo(0, 0)
}
let handleCurrentChange = (val) => {
  page.value.currentPage = val
  if (searchIs.value) {
    onLoad()
  } else {
    query.value.getOutTime = ''
    query.value.teamName = ''
    onLoad()
  }
  window.scrollTo(0, 0)
}
let searchList = async () => {
  searchIs.value = true
  await onLoad()
}
let reset = async () => {
  query.value.getOutTime = ''
  query.value.teamName = ''
  await onLoad()
}
onMounted(async () => {
  await onLoad();
  loading.value = false
})
</script>

<style scoped>
::v-deep .el-table thead th {
  background: rgb(248, 249, 252);
}
.btn-8 {
  width: 100px;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(
      135deg,
      rgba(31, 112, 247, 1) 0%,
      rgba(85, 155, 254, 1) 100%
    ),
    rgba(31, 112, 247, 1);
  font-size: 14px;
  margin-left: 0 !important;
}
::v-deep .el-upload-list {
  display: none !important;
}
::v-deep .el-form--inline .el-form-item {
  width: 45%;
  align-items: center;
}
::v-deep .el-dialog__title {
  margin-bottom: 20px;
  font-size: 20px;
  display: block;
  font-weight: 700;
  color: rgb(0, 82, 217);
}
</style>