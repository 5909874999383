<template>
  <div v-loading="loading">
    <div style="display: flex;align-items: center;justify-content: space-between;padding: 30px;">
      <div style="display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">工人姓名：</div>
        <div>
          <el-input v-model="name" style="width: 220px;height: 32px;" placeholder='请输入工人姓名' />
        </div>
      </div>
      <div style="display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);display: flex;align-items: center;">
          <el-button type="primary" size="default" :icon="Search" style="width: 78px;height: 32px;" @click="searchList">搜索</el-button>
          <el-button size="default" :icon="RefreshLeft" style="width: 78px;height: 32px;margin-left:10px;" @click="reset">重置</el-button>
        </div>
      </div>
    </div>
    <div style="display: flex;align-items: center;padding: 20px 30px;">
      <el-button type="primary" @click="addTeam" class="btn-8" :icon="Upload">
        批量上传合同
      </el-button>
    </div>
    <div style="padding: 20px 30px;">
      <el-table :data="tableData" style="width: 100%;font-size:14px;">
        <el-table-column prop="code" label="编号" show-overflow-tooltip align="center" />
        <el-table-column prop="name" label="工人姓名" align="center" show-overflow-tooltip />
        <el-table-column prop="idNumber" label="证件号码" align="center" show-overflow-tooltip />
        <!-- <el-table-column prop="sex" label="性别" align="center" show-overflow-tooltip>
          <template #default="scope">{{ scope.row.sex=0?'女':'男' }}</template>
        </el-table-column> -->
        <el-table-column prop="createTime" label="创建时间" align="center" show-overflow-tooltip />
        <el-table-column label="操作" fixed="right" align="center">
          <template #default="scope">
            <el-button size="default" type="text" @click="teamExit(scope.row)">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="padding: 20px 30px;display:flex;justify-content: flex-end;">
      <el-pagination v-model:current-page="page.currentPage" v-model:page-size="page.pageSize" :page-sizes="[10, 20, 40]" :size="size" :disabled="disabled" background layout="total, sizes, prev, pager, next, jumper" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <el-dialog v-model="dialogVisible" title="查看合同" width="500">
      <div class="annexClone">
        <div style="width: fit-content;margin-right:10px;cursor: pointer;font-size:14px;">
          <a :href="rowList.attachLink" target="_blank" rel="noopener noreferrer">
            {{rowList.idNumber}}-{{rowList.name}}.pdf
          </a>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false" style="width: 78px;height: 32px;">
            关闭
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="addDialogVisible" title="上传合同" width="500">
      <div class="annex" style="margin-left: 40px;">
        <el-form label-position="top" ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon :inline="true">
          <el-form-item label="合同" prop="attachList">
            <el-upload v-model:file-list="ruleForm.attachList" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file" multiple :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="20" :on-exceed="handleExceed" :before-upload="beforeUpload" :on-success="successFile">
              <el-button type="primary" :icon="Upload">上传附件</el-button>
              <template #tip>
                <div class="el-upload__tip">
                  请上传pdf文件
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-left: 40px;margin-top:20px;">
        <div class="tit">*提示 & 规则：</div>
        <div class="jt">合同上传文件命名规则：身份证号-姓名；</div>
        <div class="jt">单文件上传最大5M；</div>
        <div class="jt">一次最多上传20个文件。</div>
      </div>
      <template #footer>
        <div class="dialog-footer" style="display: flex;justify-content: center;align-items: center">
          <el-button v-loading.fullscreen.lock="fullscreenLoading" type="primary" @click="addSuccessDialogVisible(ruleFormRef)" style="margin-right: 10px;width: 100px;height:40px;">
            确定
          </el-button>
          <el-button @click="addDialogVisible = false" style="width: 100px;height:40px;">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { RefreshLeft, Upload, Search } from '@element-plus/icons-vue'
import { onMounted, ref } from 'vue';
import { ElMessage } from 'element-plus'
import { uploadContract, CmsList } from "@/api/index.js"
let headers = { "blade-auth": 'bearer ' + localStorage.getItem('tokenuser') }
let name = ref()
let loading = ref(true)
let fullscreenLoading = ref(false)
let searchIs = ref(false)
let formSize = ref('default')
let rowList = ref({})
let ruleFormRef = ref()
let ruleForm = ref({
  attachList: [],
})
let ruleFormClone = ref({
  attachList: [],
})
let rules = ref({
  attachList: [
    { required: true, message: '请上传文件', trigger: 'change' },
  ]
})
let tableData = ref();
let disabled = ref(false)
let dialogVisible = ref(false)
let addDialogVisible = ref(false)
let size = ref('default')
let page = ref({
  total: '',
  currentPage: 1,
  pageSize: 10
});
let successFile = (response, uploadFile, uploadFiles) => {
  if (response.code == 200) {
    ruleFormClone.value.attachList.push({ name: response.data.originalName, link: response.data.name })
    ruleFormRef.value.clearValidate('attachList');
  }
}
let teamExit = (row) => {
  // teamExitApi({ id: row.id }).then(res => {
  //   console.log(res)
  // })
  rowList.value = row
  dialogVisible.value = true
}
let addTeam = () => {
  addDialogVisible.value = true
}
let addSuccessDialogVisible = async (formEl) => {
  // insertTeamApi({ projectName: '河道清理' }).then(res => {
  //   console.log(res)
  // })
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    fullscreenLoading.value = true
    if (ruleForm.value.attachList && ruleForm.value.attachList != '') {
      ruleFormRef.value.clearValidate('attachList');
    }
    if (valid) {
      uploadContract({ attachList: ruleFormClone.value.attachList }).then((res) => {
        if (res.code == 200) {
          addDialogVisible.value = false
          fullscreenLoading.value = false
          init()
        }
      })
    } else {
      console.log('error submit!', fields);
    }
  });
  fullscreenLoading.value = false
}
let handleSizeChange = (val) => {
  page.value.pageSize = val
  if (searchIs.value) {
    init()
  } else {
    name.value = ''
    init()
  }
  window.scrollTo(0, 0)
}
let handleCurrentChange = (val) => {
  page.value.currentPage = val
  if (searchIs.value) {
    init()
  } else {
    name.value = ''
    init()
  }
  window.scrollTo(0, 0)
}
let beforeUpload = (file) => {
  let isJPG = file.type === 'application/pdf';
  if (!isJPG) {
    ElMessage.warning('上传文件只能是 pdf 格式!');
    return false;
  }
  let idCard = file.name.slice(0, 18);
  let regex = /^[1-9]\d{5}(18|19|20|21|22)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|[Xx])$/;
  if (!regex.test(idCard)) {
    ElMessage.error("请规范文件命名：'身份证号-姓名' ！");
    return false;
  }
  let maxSize = 5 * 1024 * 1024;
  if (file.size > maxSize) {
    ElMessage.warning('文件大小超过限制，请选择小于5MB的文件。');
    return false;
  } else {
    console.log('文件大小符合要求，可以上传。');
  }
  return true;
}
let handleRemove = (file, uploadFiles) => {
  console.log(file, uploadFiles)
}
let handlePreview = (uploadFile) => {
  console.log(uploadFile)
}
let handleExceed = (files, uploadFiles) => {
  ElMessage.warning(
    `The limit is 3, you selected ${files.length} files this time, add up to ${files.length + uploadFiles.length
    } totally`
  )
}
let beforeRemove = (uploadFile, uploadFiles) => {
  return true
}
let searchList = async () => {
  searchIs.value = true
  await init()
}
let reset = async () => {
  name.value = ''
  await init()
}
let init = async () => {
  await CmsList({ currentPage: page.value.currentPage, pageSize: page.value.pageSize, name: name.value }).then(res => {
    tableData.value = res.data.records
    page.value.total = res.data.total
  })
}
onMounted(async () => {
  await init()
  loading.value = false
})
</script>

<style scoped>
a {
  text-decoration: none;
}
::v-deep .el-form--inline .el-form-item {
  width: 100%;
}
::v-deep .el-form-item__content {
  width: 295px;
}
::v-deep .upload-demo {
  width: 100%;
}
.btn-8 {
  width: 120px;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(
      135deg,
      rgba(31, 112, 247, 1) 0%,
      rgba(85, 155, 254, 1) 100%
    ),
    rgba(31, 112, 247, 1);
  font-size: 14px;
}
::v-deep .el-table thead th {
  background: rgb(248, 249, 252);
}
::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: 700;
  color: rgb(0, 82, 217);
}
.annex {
  width: 350px;
  border-radius: 6px;
  background: rgba(248, 249, 252, 1);
  border: 1px dashed rgba(194, 198, 206, 1);
  padding: 20px;
  box-sizing: border-box;
}
.annexClone {
  width: 100%;
  border-radius: 6px;
  background: rgba(248, 249, 252, 1);
  border: 1px dashed rgba(194, 198, 206, 1);
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.tit {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
}
.jt {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  height: 20px;
}
</style>