<template>
  <div class="my-orders" v-loading="loading">
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;padding:20px;">
        <div style="display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">项目名称：</div>
          <div>
            <el-input v-model="projectName" style="width: 220px;height: 32px;" placeholder='请输入项目名称' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">选择区域：</div>
          <div>
            <el-cascader v-model="optionsValue" placeholder='请选择区域' :options="treeArr" :props="optionProps" size='default' style="width: 220px;height: 32px;" @change="handleChange" />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">申请状态：</div>
          <div>
            <el-select v-model="applyStateValue" placeholder="请选择申请状态" size='default' style="width: 220px;height: 32px;">
              <el-option :label="item.dictValue" :value="item.dictKey" v-for="item in applyStateObj" :key="item.id" />
            </el-select>
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;margin-top: 20px;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">缴纳状态：</div>
          <div>
            <el-select v-model="payStateValue" placeholder="请选择缴纳状态" size='default' style="width: 220px;height: 32px;">
              <el-option :label="item.dictValue" :value="item.dictKey" v-for="item in payStateObj" :key="item.id" />
            </el-select>
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;flex-direction: row-reverse;margin-top: 20px;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">
            <el-button @click="SearchBtn" type="primary" size="default" :icon="Search" style="width: 78px;height: 32px;">搜索</el-button>
            <el-button @click="Reset" size="default" :icon="RefreshLeft" style="width: 78px;height: 32px;margin-left:10px;">重置</el-button>
          </div>
        </div>
      </div>
      <div>
        <div class="box" v-for="item in ordersArr" :key="item.id">
          <div class="top-box" style="justify-content: space-between;">
            <div class="top-box">
              <div class="status">{{StatusTitle(item.flowState,item.applyWay)}}</div>
              <div class="line"></div>
              <div class="name">{{item.projectName}}</div>
            </div>
            <div style="margin-right:30px;">
              <div class="status">{{item.applyWay == 'readyMoney' ? '现金缴存' : item.applyWay == 'bank'?'银行保函': item.applyWay == 'bond'?'保证保险':'担保保函'}}</div>
            </div>

          </div>
          <div class="bottom-box">
            <div class="text-status">
              <div class="flex">
                <div style="display: flex;margin-left: 23px;min-width: 115px;">
                  <div class="text tit">保函期限：</div>
                  <div class="text box1" v-if="item.applyStartTime&&item.applyEndTime">
                    {{item.applyStartTime.slice(0,11)}} 至 {{item.applyEndTime.slice(0,11)}}
                  </div>
                  <div class="text box1" v-else>
                    暂无保函日期
                  </div>
                </div>
                <div style="display: flex;margin-left: 23px;min-width: 115px;">
                  <div class="text tit">承保公司：</div>
                  <div class="text box1" v-if="item.institutionName">
                    <el-tooltip class="box-item" effect="dark" :content="item.institutionName" placement="top">
                      {{item.institutionName}}
                    </el-tooltip>
                  </div>
                  <div class="text box1" v-else>
                    暂无承保公司
                  </div>
                </div>
              </div>
              <div class="flex">
                <div style="display: flex;margin-left: 23px;min-width: 115px;">
                  <div class="text tit">区域：</div>
                  <div class="text box2" v-if="item.regionName">
                    <el-tooltip class="box-item" effect="dark" :content="item.regionName" placement="top">
                      {{item.regionName}}
                    </el-tooltip>
                  </div>
                  <div class="text box2" v-else>
                    暂无区域
                  </div>
                </div>
                <div style="display: flex;margin-left: 23px;min-width: 115px;">
                  <div class="text tit">缴纳状态：</div>
                  <div class="text" :class="itemState(item.payState)">
                    {{Status(item.payState)}}
                  </div>
                </div>
              </div>
              <div class="flex">
                <div style="display: flex;margin-left: 23px;min-width: 115px;">
                  <div class="text tit">申请状态：</div>
                  <div class="text box3" :class="itemClass(item.flowState)" v-if="item.flowState">
                    <el-tooltip class="box-item" effect="dark" :content="getStatus(item.flowState)" placement="top">
                      {{getStatus(item.flowState)}}
                    </el-tooltip>
                  </div>
                  <div v-else>
                    暂无申请状态
                  </div>
                </div>
                <div style="display: flex;margin-left: 23px;min-width: 115px;">
                  <div class="text tit">保单号：</div>
                  <div class="text box3" v-if="item.guaranteeSn">
                    <el-tooltip class="box-item" effect="dark" :content="item.guaranteeSn" placement="top">
                      {{item.guaranteeSn}}
                    </el-tooltip>
                  </div>
                  <div class="text box3" v-else>
                    暂无保单号
                  </div>
                </div>
              </div>
            </div>
            <div class="box-line"></div>
            <div class="btn-arr">
              <el-button type="primary" size="default" :icon="View" class="btn-7" @click="ViewDetails(item.projectId,item.applyInstitutionId)">查看详情</el-button>
              <el-button size="default" v-if="StatusTitle(item.flowState)=='待申请保函'&&item.applyWay!='readyMoney'&&item.applyWay!='bank'" style="background: linear-gradient(90deg, rgba(139, 232, 227, 1) 0%, rgba(66, 199, 192, 1) 100%);border:none;" type="primary" :icon="Edit" class="btn-7" @click="CoverageOptions(item.institutionId,item.projectId,item.nature,item.relationId)">去申请</el-button>
              <el-button size="default" style="background: linear-gradient(90deg, rgba(139, 232, 227, 1) 0%, rgba(66, 199, 192, 1) 100%);border:none;" type="primary" class="btn-7" @click="fk(item.applyId)" v-if="StatusTitle(item.flowState)=='待付款'">
                <i class="el-icon-devopsX" />
                去付款
              </el-button>
              <el-button style="color: rgba(194, 198, 206, 1);;width: 84px;height:32px;" :icon="CircleClose" v-if="StatusTitle(item.flowState)!='已出函'&&(StatusTitle(item.flowState)=='待审核'||StatusTitle(item.flowState)=='待申请保函'||StatusTitle(item.flowState)=='待付款')" @click="cancelOrderMes(item.applyId,item.projectId)">取消订单</el-button>
              <el-button type="primary" size="default" :icon="Download" class="btn-7" v-if="item.guaranteeUrl&&item.guaranteeUrl!=''&&StatusTitle(item.flowState)=='已出函'&&item.applyWay!='bank'" @click="DownloadBh(item.guaranteeUrl)">
                下载保函
              </el-button>
              <el-button type="primary" size="default" class="btn-7" @click="goInvoiceApplication(item.projectId,item.applyId,item.applyPremiumAmount)" v-if="StatusTitle(item.flowState)=='已出函'&&(item.isInvoice==0||item.isInvoice==null)&&item.applyWay!='readyMoney'&&item.applyWay!='bank'">
                <i class="el-icon-devops" />
                申请发票
              </el-button>
              <el-button type="primary" size="default" class="btn-7" @click="goInvoiceApplicationDetail(item.projectId)" v-if="StatusTitle(item.flowState)=='已出函'&&item.isInvoice==1&&item.applyWay!='readyMoney'&&item.applyWay!='bank'">
                <i class="el-icon-devops" />
                发票详情
              </el-button>
              <!-- <el-button type="primary" size="default" class="btn-7" @click="ApplySurrender(item.projectId,item.applyId)" v-if="StatusTitle(item.flowState)=='已出函'&&item.surrenderType==0">
                <i class="el-icon-devopsS" />
                申请退保
              </el-button>
              <el-button type="primary" size="default" class="btn-7" @click="loookingDetails(item.projectId)" v-if="StatusTitle(item.flowState)=='已出函'&&item.surrenderType==1">
                <i class="el-icon-devopsS" />
                退保详情
              </el-button> -->
              <el-button type="primary" size="default" :icon="RefreshLeft" class="btn-7" v-if="StatusTitle(item.flowState)=='已被拒'" @click="ReissueLetter">重新开函</el-button>
              <el-button size="default" style="background: linear-gradient(90deg, rgba(139, 232, 227, 1) 0%, rgba(66, 199, 192, 1) 100%);border:none;" type="primary" class="btn-7" @click="refineCash(item.projectId,item.applyWay)" v-if="item.applyWay=='readyMoney'&&item.flowState=='APPLY_PROJECT_PASS'">
                <i class="el-icon-devopsX" />
                去完善
              </el-button>
              <el-button size="default" v-if="item.applyWay=='bank'&&item.flowState=='APPLY_PROJECT_PASS'" :icon="Tickets" style="background: linear-gradient(90deg, rgba(139, 232, 227, 1) 0%, rgba(66, 199, 192, 1) 100%);border:none;" type="primary" class="btn-7" @click="refineBank(item.projectId,item.applyWay)">
                去完善
              </el-button>
            </div>
          </div>
        </div>
        <div class="demo-pagination-block">
          <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" :page-sizes="[10, 20, 40]" :size="size" :disabled="disabled" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" background />
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="申请退保" width="500">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon>
        <el-form-item label="退保时申请人：" prop="applyName">
          <el-input v-model="ruleForm.applyName" placeholder="请输入退保时申请人" />
        </el-form-item>
        <el-form-item label="联系方式：" prop="phone">
          <el-input v-model="ruleForm.phone" placeholder="请输入联系方式" />
        </el-form-item>
        <el-form-item label="退保原因：" prop="surrenderMsg">
          <el-input v-model="ruleForm.surrenderMsg" type="textarea" placeholder="请输入退保原因" style="min-height:100px;" />
        </el-form-item>
      </el-form>
      <div v-if="RejectionInformation==null">
        <div class="red-tip">
          * 您于 {{RejectionInformation.updateTime}}的退保申请已被拒绝，被拒原因：
        </div>
        <div>
          <el-input :rows="4" type="textarea" disabled v-model="RejectionInformation.verifyMsg" />
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer" style="display: flex;justify-content: center;align-items: center">
          <el-button type="primary" @click="SuccessDialogVisible(ruleFormRef)" style="margin-right: 10px;width: 100px;height:40px;" v-if="RejectionInformation==null">
            确定
          </el-button>
          <el-button v-else type="primary" @click="againSuccessDialogVisible(ruleFormRef)" style="margin-right: 10px;width: 100px;height:40px;">
            确定
          </el-button>
          <el-button @click="dialogVisible = false" style="width: 100px;height:40px;">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { RefreshLeft, Search, View, Edit, CircleClose, Download, Tickets } from '@element-plus/icons-vue'
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from 'element-plus'
import { Alloeders, lazyTree, payState, applyState, getPayInfo, getByProject, saveSurrender, saveNewSurrender, cancelOrder } from "@/api/index.js"
let router = useRouter()
let loading = ref(true)
let dialogVisible = ref(false)
let projectName = ref("")
let optionsValue = ref([])
let regionoptionsValue = ref([])
let optionProps = ref({
  value: "id",
  label: "title",
  children: "children"
})
let currentPage = ref(1)
let pageSize = ref(10)
let size = ref('default')
let disabled = ref(false)
let total = ref()
let ordersArr = ref()
let formSize = ref('default')
let ruleFormRef = ref()
let treeArr = ref()
let payStateValue = ref()
let applyStateValue = ref()
let payStateObj = ref()
let applyStateObj = ref()
let trueSearch = ref(false)
let ruleForm = ref({
  projectId: '',
  applyName: '',
  phone: '',
  surrenderMsg: '',
  orderSn: '',
})
let rules = ref({
  applyName: [
    { required: true, message: '请输入退保时申请人', trigger: 'blur' },
    { min: 3, max: 16, message: '退保时申请人姓名长度在3-16个字符', trigger: 'blur' },
  ],
  phone: [
    { required: true, message: '请输入联系方式', trigger: 'blur' },
    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' },
    { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' },
  ],
  surrenderMsg: [
    { required: true, message: '请输入退保原因', trigger: 'blur' },
  ],
})
let RejectionInformation = ref({})
let handleSizeChange = (val) => {
  pageSize.value = val
  AlloedersArr()
  window.scrollTo(0, 0)
}
let handleCurrentChange = (val) => {
  if (trueSearch.value) {
    currentPage.value = val
    AlloedersArr()
  } else {
    projectName.value = ''
    regionoptionsValue.value = ''
    applyStateValue.value = ''
    payStateValue.value = ''
    currentPage.value = val
    AlloedersArr()
  }
  window.scrollTo(0, 0)
}
let CoverageOptions = (val, id, nature, relationId) => {
  router.push({ path: '/coverageOptions', query: { region: val, ids: id, nature: nature, relationId: relationId } })
}
let goInvoiceApplication = (projectId, applyId, applyPremiumAmount) => {
  router.push({ path: '/invoiceApplication', query: { projectId: projectId, applyId: applyId, applyPremiumAmount: applyPremiumAmount } })
}
let goInvoiceApplicationDetail = (projectId) => {
  router.push({ path: '/invoiceApplicationDetail', query: { projectId: projectId } })
}
let ViewDetails = (id, ids) => {
  router.push({ path: "/ordersDetail", query: { ids: id, applyInstitutionId: ids } })
}
let loookingDetails = (id) => {
  router.push({ path: "/surrenderDetails", query: { ids: id } })
}
let cancelOrderMes = (id, ids) => {
  ElMessageBox.confirm(
    '确定要取消该订单吗',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      buttonSize: 'default',
    }
  ).then(async () => {
    await cancelOrder({ orderId: id, projectId: ids }).then(res => {
      if (res.code == 200) {
        ElMessage.success('取消订单成功')
        AlloedersArr()
      } else {
        ElMessage.warning('请稍后再次尝试')
      }
    })
  }).catch(() => {
    return
  })
}
let ApplySurrender = async (projectId, orderSn) => {
  dialogVisible.value = true
  ruleForm.value.projectId = projectId
  ruleForm.value.orderSn = orderSn
  await getByProject({ projectId: projectId, verifyState: '99' }).then(res => {
    console.log(res, 'res');
    if (res.data && res.data != null && res.data.updateTime) {
      res.data.updateTime = res.data.updateTime.slice(0, 11)
    }
    RejectionInformation.value = res.data
  })
}
let SuccessDialogVisible = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      saveSurrender(ruleForm.value).then((res) => {
        if (res.code == 200) {
          ElMessage.success('申请退保成功')
          dialogVisible.value = false
        } else {
          ElMessage.warning('请稍后再次尝试')
        }
      })
    } else {
      console.log('error submit!', fields);
    }
  });
}
let againSuccessDialogVisible = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      ruleForm.value.id = RejectionInformation.value.id
      saveNewSurrender(ruleForm.value).then((res) => {
        if (res.code == 200) {
          ElMessage.success('申请退保成功')
          dialogVisible.value = false
        } else {
          ElMessage.warning('请稍后再次尝试')
        }
      })
    } else {
      console.log('error submit!', fields);
    }
  });
}
let AlloedersArr = async () => {
  await Alloeders({ current: currentPage.value, size: pageSize.value, projectName: projectName.value, region: regionoptionsValue.value, applyState: applyStateValue.value, payState: payStateValue.value }).then((res) => {
    console.log(res, 'res');
    ordersArr.value = res.data.records
    total.value = res.data.total
  })
}
let payStateMes = async () => {
  await payState('pay_state').then((res) => {
    payStateObj.value = res.data
  })
}
let applyStateMes = async () => {
  await applyState('apply_state').then((res) => {
    applyStateObj.value = res.data
  })
}
let getStatus = (val) => {
  if (val == 'APPLY_PROJECT') {
    return "人社审核中"
  } else if (val == 'APPLY_PROJECT_PASS') {
    return "人社审核通过"
  } else if (val == 'APPLY_PROJECT_REFUSE') {
    return "人社审核拒绝"
  } else if (val == 'APPLY_INFO') {
    return "承保机构审核中"
  } else if (val == 'APPLY_INFO_PASS') {
    return "承保机构审核通过"
  } else if (val == 'APPLY_INFO_REFUSE') {
    return "承保机构审核拒绝"
  } else if (val == 'APPLY_GUARANTEE') {
    return "监管确认中"
  } else if (val == 'APPLY_GUARANTEE_SUCCESS') {
    return "保函审核通过"
  } else if (val == 'APPLY_GUARANTEE_REFUSE') {
    return "保函审核拒绝"
  } else {
    return "暂无状态"
  }
}
let StatusTitle = (val, sta) => {
  if (val == 'APPLY_PROJECT') {
    return "待审核"
  } else if (val == 'APPLY_PROJECT_PASS' && sta == 'readyMoney') {
    return "待完善现金缴存信息"
  } else if (val == 'APPLY_PROJECT_PASS' && sta == 'bank') {
    return "待完善银行保函信息"
  } else if (val == 'APPLY_PROJECT_PASS') {
    return "待申请保函"
  } else if (val == 'APPLY_PROJECT_REFUSE') {
    return "已被拒"
  } else if (val == 'APPLY_INFO') {
    return "待审核"
  } else if (val == 'APPLY_INFO_PASS') {
    return "待付款"
  } else if (val == 'APPLY_INFO_REFUSE') {
    return "已被拒"
  } else if (val == 'APPLY_GUARANTEE') {
    return "监管确认中"
  } else if (val == 'APPLY_GUARANTEE_SUCCESS') {
    return "已出函"
  } else if (val == 'APPLY_GUARANTEE_REFUSE') {
    return "已被拒"
  } else if (val == 'CANCEL') {
    return "已取消"
  } else {
    return "暂无状态"
  }
}
let Status = (val) => {
  if (val == 'PENDING') {
    return "待付款"
  } else if (val == 'PAID') {
    return "已付款"
  } else {
    return "暂无支付状态"
  }
}
let itemClass = (flowState) => {
  const status = getStatus(flowState);
  if (status.includes('通过')) {
    return 'green';
  } else if (status.includes('拒绝')) {
    return 'red';
  } else if (status.includes('审核中')) {
    return 'yellow';
  } else {
    return '';
  }
}
let itemState = (flowState) => {
  const status = Status(flowState);
  if (status.includes('已付款')) {
    return 'green';
  } else if (status.includes('待付款')) {
    return 'red';
  } else {
    return '';
  }
}
let SearchBtn = async () => {
  trueSearch.value = true
  await AlloedersArr()
}
let Reset = async () => {
  projectName.value = ''
  regionoptionsValue.value = ''
  applyStateValue.value = ''
  payStateValue.value = ''
  optionsValue.value = ''
  await AlloedersArr()
}
let handleChange = () => {
  regionoptionsValue.value = Object.values(optionsValue.value).join(',');
}
let lazyTreeArr = async () => {
  await lazyTree().then((res) => {
    let slicedData = res.data.slice(1);
    treeArr.value = slicedData
    localStorage.setItem('Tree', JSON.stringify(treeArr.value))
  })
}
let fk = async (applyId) => {
  await getPayInfo({ orderId: applyId }).then((res) => {
    if (res.code == 200 && res.data.switch == 1) {
      window.location.href = res.data.payUrl
    }
    if (res.code == 200 && res.data.switch == 0) {
      router.push({ path: '/orderPay', query: { orderId: applyId } })
    }
  })
}
let DownloadBh = (url) => {
  console.log(url);
  window.open(url)
}
let ReissueLetter = () => {
  router.push({ path: '/deposit' })
}
let refineBank = (projectId, applyWay) => {
  router.push({ path: '/improveBank', query: { orderId: projectId, applyWay: applyWay } })
}
let refineCash = (projectId, applyWay) => {
  router.push({ path: '/perfectCash', query: { orderId: projectId, applyWay: applyWay } })
}
onMounted(async () => {
  await AlloedersArr()
  loading.value = false
  await payStateMes()
  await applyStateMes()
  if (localStorage.getItem('Tree')) {
    treeArr.value = JSON.parse(localStorage.getItem('Tree'))
  } else {
    await lazyTreeArr()
  }
})
</script>
<style scoped>
.red-tip {
  height: 52px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
  text-align: center;
  line-height: 52px;
}
::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: 700;
  color: rgb(0, 82, 217);
}
::v-deep .el-textarea__inner {
  min-height: 100px !important;
}
.demo-pagination-block {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
}
::v-deep .el-button + .el-button {
  margin-left: 0;
}
::v-deep .el-icon-devops {
  background: url("./../../static/piao.png") center no-repeat;
  font-size: 12px;
  background-size: cover;
  margin-right: 7px;
}

::v-deep .el-icon-devops:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
::v-deep .el-icon-devopsX {
  background: url("./../../static/jf.png") center no-repeat;
  font-size: 12px;
  background-size: cover;
  margin-right: 7px;
}

::v-deep .el-icon-devopsX:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
::v-deep .el-icon-devopsS {
  background: url("./../../static/tui.png") center no-repeat;
  font-size: 20px;
  background-size: cover;
  margin-right: 7px;
}

::v-deep .el-icon-devopsS:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
.tit {
  white-space: nowrap;
}
.btn-7 {
  width: 84px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(
      135deg,
      rgba(31, 112, 247, 1) 0%,
      rgba(85, 155, 254, 1) 100%
    ),
    rgba(0, 82, 217, 1);
}
.my-orders {
  padding: 15px;
  box-sizing: border-box;
}
.red {
  color: rgb(212, 48, 48) !important;
}
.green {
  color: rgb(0, 186, 173) !important;
}
.yellow {
  color: rgb(255, 141, 26) !important;
}
.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.btn-arr {
  width: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.box1 {
  max-width: 188px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box2 {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box3 {
  max-width: 187px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text {
  font-size: 14px;
  font-weight: 400;
  color: rgb(32, 35, 50);
  text-align: left;
}
.box-line {
  width: 0px;
  height: 100px;
  border: 1px solid rgba(243, 246, 255, 1);
  display: flex;
  align-self: center;
  margin: 0 30px;
}
.text-status {
  min-width: 73%;
  display: flex;
  justify-content: space-between;
}
.box {
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 38px;
}
.bottom-box {
  height: 140px;
  display: flex;
}
.top-box {
  display: flex;
  align-items: center;
  height: 58px;
  border-radius: 4px 4px 0px 0px;
  background: rgb(243, 246, 255);
}
.status {
  font-size: 22px;
  font-weight: 500;
  color: rgba(31, 112, 247, 1);
  margin-left: 23px;
}
.name {
  font-size: 18px;
  font-weight: 500;
  color: rgba(32, 35, 50, 1);
}
.line {
  width: 2px;
  height: 30px;
  border-radius: 2px;
  background: rgba(194, 198, 206, 1);
  margin: 0 30px 0 35px;
}
</style>