<template>
  <div class="my-orders" v-loading="loading">
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;padding:20px;">
        <div style="display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">保函状态：</div>
          <div>
            <el-select v-model="guaranteeType" placeholder="请选择保函状态" style="width: 220px;height: 32px;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">保函编号：</div>
          <div>
            <el-input v-model="policySn" style="width: 220px;height: 32px;" placeholder='请输入保函编号' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">订单编号：</div>
          <div>
            <el-input v-model="orderId" style="width: 220px;height: 32px;" placeholder='请输入订单编号' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;margin-top: 20px;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">项目名称：</div>
          <div>
            <el-input v-model="projectName" style="width: 220px;height: 32px;" placeholder='请输入项目名称' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;margin-top: 20px;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">承保公司：</div>
          <div>
            <el-input v-model="institutionName" style="width: 220px;height: 32px;" placeholder='请输入承保公司' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;flex-direction: row-reverse;margin-top: 20px;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">
            <el-button @click="SearchBtn" type="primary" size="default" :icon="Search" style="width: 78px;height: 32px;">搜索</el-button>
            <el-button @click="Reset" size="default" :icon="RefreshLeft" style="width: 78px;height: 32px;margin-left:10px;">重置</el-button>
          </div>
        </div>
      </div>
      <div>
        <div class="box" v-for="item in ordersArr" :key="item.id">
          <div class="top-box">
            <div class="status">{{StatusTitle(item.isCompensation,item.expireStatus)}}</div>
          </div>
          <div class="bottom-box">
            <div class="text-status">
              <div class="flex">
                <div style="display: flex;margin-left: 20px;min-width: 115px;">
                  <div class="text tit">缴存期限：</div>
                  <div class="text box1" v-if="item.startTime&&item.endTime">
                    {{item.startTime.slice(0,11)}} 至 {{item.endTime.slice(0,11)}}
                  </div>
                  <div class="text box1" v-else>
                    暂无缴存期限
                  </div>
                </div>
                <div style="display: flex;margin-left: 20px;min-width: 115px;">
                  <div class="text tit">项目名称：</div>
                  <div class="text box1" v-if="item.projectName">
                    <el-tooltip effect="dark" :content="item.projectName" placement="top">
                      {{item.projectName}}
                    </el-tooltip>
                  </div>
                  <div class="text box1" v-else>
                    暂无项目名称
                  </div>
                </div>
              </div>
              <div class="flex">
                <div style="display: flex;margin-left: 20px;min-width: 115px;">
                  <div class="text tit">承保公司：</div>
                  <div class="text box3" style="width: 154px;" v-if="item.institutionName">
                    <el-tooltip effect="dark" :content="item.institutionName" placement="top">
                      {{item.institutionName}}
                    </el-tooltip>
                  </div>
                  <div v-else style="font-size:14px;">
                    暂无承保公司
                  </div>
                </div>
                <div style="display: flex;margin-left: 20px;min-width: 115px;">
                  <div class="text tit">保费(元)：</div>
                  <div class="text box3" v-if="item.premiumAmount">
                    {{item.premiumAmount}}
                  </div>
                  <div class="text box3" v-else>
                    暂无保费
                  </div>
                </div>
              </div>
              <div class="flex">
                <div style="display: flex;margin-left: 20px;min-width: 115px;">
                  <div class="text tit" style="white-space: break-spaces;width: 69.9px;">承保公司联系方式:</div>
                  <div class="text box3" v-if="item.institutionContact" style="white-space: nowrap;line-height: 38px;">
                    <el-tooltip effect="dark" :content="item.institutionContact" placement="top">
                      {{item.institutionContact}}
                    </el-tooltip>
                  </div>
                  <div v-else style="font-size:14px;line-height: 38px;">
                    暂无联系方式
                  </div>
                </div>
                <div style="display: flex;margin-left: 20px;min-width: 115px;visibility: hidden;">
                  <div class="text tit" style="white-space: break-spaces;width: 74px;">承保公司联系方式：</div>
                  <div class="text box3" v-if="item.institutionContact" style="white-space: nowrap;line-height: 38px;">
                    {{item.institutionContact}}
                  </div>
                  <div v-else style="font-size:14px;line-height: 38px;">
                    暂无联系方式
                  </div>
                </div>
              </div>
            </div>
            <div class="box-line"></div>
            <div class="btn-arr">
              <el-button type="primary" size="default" :icon="View" class="btn-7" @click="ViewDetails(item.projectId)">
                查看详情
              </el-button>
              <el-button type="primary" size="default" :icon="Tickets" class="btn-7" @click="goInvoiceApplication(item.projectId)">
                立即续保
              </el-button>
            </div>
          </div>
        </div>
        <div class="demo-pagination-block">
          <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" :page-sizes="[10, 20, 40]" :size="size" :disabled="disabled" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" background />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { RefreshLeft, Search, View, Tickets } from '@element-plus/icons-vue'
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { selfReadyMoneyOrder } from "@/api/index.js"
let router = useRouter()
let loading = ref(true)
let projectName = ref("")
let guaranteeType = ref("")
let policySn = ref("")
let orderId = ref("")
let institutionName = ref("")
let currentPage = ref(1)
let pageSize = ref(10)
let size = ref('default')
let disabled = ref(false)
let total = ref()
let ordersArr = ref()
let trueSearch = ref(false)
let options = ref([
  { value: '1', label: '已缴存' },
  { value: '2', label: '已过期' },
  { value: '3', label: '理赔中' },
  { value: '4', label: '已理赔' },
])
let handleSizeChange = (val) => {
  pageSize.value = val
  AlloedersArr()
  window.scrollTo(0, 0)
}
let handleCurrentChange = (val) => {
  if (trueSearch.value) {
    currentPage.value = val
    AlloedersArr()
  } else {
    projectName.value = ''
    guaranteeType.value = ''
    policySn.value = ''
    orderId.value = ''
    institutionName.value = ''
    currentPage.value = val
    AlloedersArr()
  }
  window.scrollTo(0, 0)
}
let goInvoiceApplication = (projectId) => {
  router.push({ path: '/deposit', query: { projectId: projectId, type: '1' } })
}
let ViewDetails = (id) => {
  router.push({ path: "/ordersDetail", query: { ids: id } })
}
let AlloedersArr = async () => {
  await selfReadyMoneyOrder({ current: currentPage.value, size: pageSize.value, policySn: policySn.value, orderId: orderId.value, projectName: projectName.value, institutionName: institutionName.value, guaranteeType: guaranteeType.value }).then((res) => {
    ordersArr.value = res.data.records
    total.value = res.data.total
  })
}
let StatusTitle = (isCompensation, expireStatus) => {
  if (isCompensation == '3') {
    return "已理赔"
  } else if (isCompensation == '1' || isCompensation == '2') {
    return "理赔中"
  } else if (expireStatus == '1') {
    return "已缴存"
  } else if (expireStatus == '99') {
    return "已过期"
  } else {
    return "暂无状态"
  }
}
let SearchBtn = async () => {
  trueSearch.value = true
  await AlloedersArr()
}
let Reset = async () => {
  projectName.value = ''
  guaranteeType.value = ''
  policySn.value = ''
  orderId.value = ''
  institutionName.value = ''
  await AlloedersArr()
}
onMounted(async () => {
  await AlloedersArr()
  loading.value = false
})
</script>
<style scoped>
.w260 {
  width: 260px;
  height: 40px;
}
::v-deep .el-select__wrapper {
  height: 100%;
}
::v-deep .el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.w260 {
  width: 260px;
}
.red-tip {
  height: 52px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
  text-align: center;
  line-height: 52px;
}
::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: 700;
  color: rgb(0, 82, 217);
}
::v-deep .el-textarea__inner {
  min-height: 100px !important;
}
.demo-pagination-block {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
}
::v-deep .el-button + .el-button {
  margin-left: 0;
}
::v-deep .el-icon-devops {
  background: url("./../../static/piao.png") center no-repeat;
  font-size: 12px;
  background-size: cover;
  margin-right: 7px;
}

::v-deep .el-icon-devops:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
::v-deep .el-icon-devopsX {
  background: url("./../../static/jf.png") center no-repeat;
  font-size: 12px;
  background-size: cover;
  margin-right: 7px;
}

::v-deep .el-icon-devopsX:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
::v-deep .el-icon-devopsS {
  background: url("./../../static/tui.png") center no-repeat;
  font-size: 20px;
  background-size: cover;
  margin-right: 7px;
}

::v-deep .el-icon-devopsS:before {
  content: "替";
  font-size: 12px;
  visibility: hidden;
}
.tit {
  white-space: nowrap;
}
.btn-7 {
  width: 84px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(
      135deg,
      rgba(31, 112, 247, 1) 0%,
      rgba(85, 155, 254, 1) 100%
    ),
    rgba(0, 82, 217, 1);
}
.my-orders {
  padding: 15px;
  box-sizing: border-box;
}
.red {
  color: rgb(212, 48, 48) !important;
}
.green {
  color: rgb(0, 186, 173) !important;
}
.yellow {
  color: rgb(255, 141, 26) !important;
}
.flex {
  width: 278px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 5%;
}
.flex:nth-child(3) {
  margin-right: 0;
}
.btn-arr {
  width: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
}
.box1 {
  max-width: 188px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box2 {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box3 {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text {
  font-size: 14px;
  font-weight: 400;
  color: rgb(32, 35, 50);
  text-align: left;
}
.box-line {
  width: 0px;
  height: 100px;
  border: 1px solid rgba(243, 246, 255, 1);
  display: flex;
  align-self: center;
  margin: 0 30px;
}
.text-status {
  min-width: 73%;
  display: flex;
}
.box {
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 38px;
}
.bottom-box {
  height: 140px;
  display: flex;
}
.top-box {
  display: flex;
  align-items: center;
  height: 58px;
  border-radius: 4px 4px 0px 0px;
  background: rgb(243, 246, 255);
}
.status {
  font-size: 22px;
  font-weight: 500;
  color: rgba(31, 112, 247, 1);
  margin-left: 23px;
}
.name {
  font-size: 18px;
  font-weight: 500;
  color: rgba(32, 35, 50, 1);
}
.line {
  width: 2px;
  height: 30px;
  border-radius: 2px;
  background: rgba(194, 198, 206, 1);
  margin: 0 30px 0 35px;
}
</style>