<template>
  <div v-loading="loading">
    <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;padding: 30px;">
      <div style="display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">项目名称：</div>
        <div>
          <el-input v-model="query.projectName" style="width: 220px;height: 40px;" placeholder='请输入项目名称' />
        </div>
      </div>
      <div style="display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">项目编号：</div>
        <div>
          <el-input v-model="query.projectCode" style="width: 220px;height: 40px;" placeholder='请输入项目编号' />
        </div>
      </div>
      <div style="display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">保函编号：</div>
        <div>
          <el-input v-model="query.policySn" style="width: 220px;height: 40px;" placeholder='请输入保函编号' />
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 20px;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">协查人：</div>
        <div>
          <el-input v-model="query.checkName" style="width: 220px;height: 40px;" placeholder='请输入协查人' />
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 20px;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">协查日期：</div>
        <div>
          <el-date-picker v-model="query.checkTime[0]" placeholder="请选择开始协查日期" value-format="YYYY-MM-DD HH:mm:ss" type="date" style="width: 155px;height:40px;" />
          -
          <el-date-picker v-model="query.checkTime[1]" placeholder="请选择结束协查日期" value-format="YYYY-MM-DD HH:mm:ss" type="date" style="width: 155px;height:40px;" />
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-top: 20px;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);display: flex;align-items: center;">
          <el-button type="primary" size="default" :icon="Search" style="width: 78px;height: 40px;" @click="searchList">搜索</el-button>
          <el-button size="default" :icon="RefreshLeft" style="width: 78px;height: 40px;margin-left:10px;" @click="reset">重置</el-button>
        </div>
      </div>
    </div>
    <div style="display: flex;align-items: center;padding: 0px 30px;">
      <el-button type="primary" @click="addTeam" class="btn-8" :icon="CirclePlus">
        新增
      </el-button>
    </div>
    <div style="padding: 20px 30px;">
      <el-table :data="tableData" style="width: 100%;font-size:14px;">
        <el-table-column prop="id" label="编号" show-overflow-tooltip align="center" />
        <el-table-column prop="projectName" label="项目名称" align="center" show-overflow-tooltip />
        <el-table-column prop="projectCode" label="项目编号" align="center" show-overflow-tooltip />
        <el-table-column prop="policySn" label="保函编号" align="center" show-overflow-tooltip />
        <el-table-column prop="enclosure" label="附件" align="center" show-overflow-tooltip>
          <template #default="scope"><span style="color: rgb(0, 82, 217);cursor: pointer;" @click="download(scope.row.enclosure[0].originalName)">{{ scope.row.enclosure[0].name }}</span></template>
        </el-table-column>
        <el-table-column prop="checkTime" label="协查日期" align="center" show-overflow-tooltip />
        <el-table-column prop="checkName" label="协查人" align="center" show-overflow-tooltip />
      </el-table>
    </div>
    <div style="padding: 20px 30px;display:flex;justify-content: flex-end;">
      <el-pagination v-model:current-page="page.currentPage" v-model:page-size="page.pageSize" :page-sizes="[10, 20, 40]" :size="size" :disabled="disabled" background layout="total, sizes, prev, pager, next, jumper" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <el-dialog v-model="dialogVisible" title="新增保后协查" width="800">
      <div>
        <el-form ref="ruleFormRef" style="display: flex;flex-wrap: wrap;justify-content: space-around;" :inline="true" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon>
          <el-form-item label="项目名称：" prop="projectName">
            <el-select v-model="ruleForm.projectName" placeholder="请选择项目名称" style="width: 220px;height: 40px;" @change="selectChange">
              <el-option v-for="item in projectList" :key="item.id" :label="item.projectName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="项目编号：" prop="projectCode">
            <el-input disabled v-model="ruleForm.projectCode" style="width: 220px;height: 40px;" />
          </el-form-item>
          <el-form-item label="保函编号：" prop="policySn" v-if="showValue">
            <el-input disabled v-model="ruleForm.policySn" style="width: 220px;height: 40px;" />
          </el-form-item>
          <el-form-item label="协查日期：" prop="checkTime">
            <el-date-picker v-model="ruleForm.checkTime" placeholder="请选择协查日期" value-format="YYYY-MM-DD HH:mm:ss" type="date" style="width: 220px;height: 40px;" />
          </el-form-item>
          <el-form-item label="协查人：" prop="checkName">
            <el-input v-model="ruleForm.checkName" placeholder="请输入协查人" style="width: 220px;height: 40px;" />
          </el-form-item>
          <el-form-item label="xxx" style="visibility: hidden;" v-if="showValue">
            <el-input style="width: 220px;height: 40px;" />
          </el-form-item>
          <el-form-item label="附件：" prop="enclosure">
            <div class="annex">
              <el-upload :file-list="ruleForm.enclosure" class="upload-demo" :headers="headers" action="/api/blade-resource/oss/endpoint/put-file-attach" multiple :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="999" :on-exceed="handleExceed" :on-success="successFile">
                <template #trigger>
                  <el-button type="primary" :icon="Upload">上传附件</el-button>
                </template>
                <template #default>
                  <el-button type="primary" style="margin-left:10px;margin-top:-7px;" :icon="Download" @click="DownloadList">
                    下载模板
                  </el-button>
                </template>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="xxx" style="visibility: hidden;">
            <el-input style="width: 90px;height: 40px;" />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer" style="display: flex;justify-content: center;">
          <el-button type="primary" @click="rowSave(ruleFormRef)" style="width: 100px;height: 40px;">
            保存
          </el-button>
          <el-button @click="dialogVisible = false" style="width: 100px;height: 40px;">
            取消
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { RefreshLeft, Search, CirclePlus, Upload, Download } from '@element-plus/icons-vue'
import { onMounted, ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import { getList, getUnCheckProject, getDetailByPersona, add } from "@/api/index.js"
let headers = { "blade-auth": 'bearer ' + localStorage.getItem('tokenuser') }
let name = ref()
let loading = ref(true)
let searchIs = ref(false)
let showValue = ref(true)
let tableData = ref();
let myPersonDeptId = ref(null)
let disabled = ref(false)
let dialogVisible = ref(false)
let size = ref('default')
let page = ref({
  total: '',
  currentPage: 1,
  pageSize: 10
});
let query = ref({
  projectName: '',
  projectCode: '',
  policySn: '',
  checkName: '',
  checkTime: ''
})
let rules = ref({
  projectName: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
  projectCode: [{ required: true, message: '请输入项目编号', trigger: 'blur' }],
  policySn: [{ required: true, message: '请输入保函编号', trigger: 'blur' }],
  checkTime: [{ required: true, message: '请选择日期', trigger: 'change' }],
  enclosure: [{ required: true, message: '请上传附件', trigger: 'change' }]
})
let personaConfigData = ref()
let projectList = ref()
let formSize = ref('default')
let ruleFormRef = ref()
let ruleForm = ref({
  enclosure: []
})
let selectChange = (val) => {
  let select = projectList.value.find(e => e.id == val);
  if (select.applyWay == 'readyMoney') {
    showValue.value = false
  } else {
    showValue.value = true
  }
  if (select) {
    ruleForm.value.projectId = select.id;
    ruleForm.value.projectName = select.projectName;
    ruleForm.value.projectCode = select.projectCode;
    ruleForm.value.policySn = select.policySn;
    myPersonDeptId.value = select.personDeptId
    getPersonaConfig()
  }
}
let addTeam = async () => {
  dialogVisible.value = true
  showValue.value = true
  await getUnCheckProjectList()
  ruleFormRef.value.clearValidate('projectName');
  ruleFormRef.value.clearValidate('projectCode');
  ruleFormRef.value.clearValidate('policySn');
  ruleFormRef.value.clearValidate('checkTime');
  ruleFormRef.value.clearValidate('enclosure');
  ruleForm.value.projectName = ''
  ruleForm.value.projectCode = ''
  ruleForm.value.policySn = ''
  ruleForm.value.checkTime = ''
  ruleForm.value.checkName = ''
  ruleForm.value.enclosure = []
}
let handleSizeChange = (val) => {
  page.value.pageSize = val
  if (searchIs.value) {
    init()
  } else {
    name.value = ''
    init()
  }
  window.scrollTo(0, 0)
}
let handleCurrentChange = (val) => {
  page.value.currentPage = val
  if (searchIs.value) {
    init()
  } else {
    name.value = ''
    init()
  }
  window.scrollTo(0, 0)
}
let searchList = async () => {
  searchIs.value = true
  await init()
}
let reset = async () => {
  for (let key in query.value) {
    query.value[key] = '';
  }
  await init()
}
let init = async () => {
  let values = Object.assign({}, query.value);
  if (values.checkTime && values.checkTime.length == 2) {
    values.checkTimeStart = values.checkTime[0];
    values.checkTimeEnd = values.checkTime[1];
    values.checkTime = null;
  }
  await getList(page.value.currentPage, page.value.pageSize, values).then(res => {
    page.value.total = res.data.total;
    for (let i = 0; i < res.data.records.length; i++) {
      res.data.records[i].enclosure = JSON.parse(res.data.records[i].enclosure)
    }
    tableData.value = res.data.records;
  });
}
let handlePreview = (uploadFile) => {
  ElMessageBox.confirm('确认下载此附件吗？', '附件下载',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      window.open(uploadFile.response.data.link)
    }).catch(() => {
      return
    })
}
let handleExceed = (files, uploadFiles) => {
  ElMessage.warning(
    `The limit is 3, you selected ${files.length} files this time, add up to ${files.length + uploadFiles.length
    } totally`
  )
}
let handleRemove = (file, fileList) => {
  ruleForm.value.enclosure = fileList;
  ruleFormRef.value.clearValidate('enclosure');
}
let beforeRemove = (uploadFile, uploadFiles) => {
  return true
}
let successFile = (response, uploadFile, uploadFiles) => {
  if (!ruleForm.value.enclosure) {
    ruleForm.value.enclosure = [];
  }
  if (response.code == 200) {
    ruleForm.value.enclosure.push({ domain: response.data.domain, link: response.data.link, name: response.data.originalName, originalName: response.data.name })
    ruleFormRef.value.clearValidate('enclosure');
  }
}
let DownloadList = () => {
  if (myPersonDeptId.value && myPersonDeptId.value != null) {
    window.open(`${personaConfigData.value.guaranteeCheckTemplate[0].link}`)
  } else {
    ElMessage.warning('请先选择项目后下载模板')
  }
}
let getPersonaConfig = async () => {
  getDetailByPersona({ id: myPersonDeptId.value }).then(res => {
    res.data.guaranteeCheckTemplate = JSON.parse(res.data.guaranteeCheckTemplate);
    personaConfigData.value = res.data;
  })
}
let getUnCheckProjectList = async () => {
  await getUnCheckProject().then(res => {
    projectList.value = res.data;
  })
}
let rowSave = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (ruleForm.value.enclosure && ruleForm.value.enclosure != '') {
      ruleFormRef.value.clearValidate('enclosure');
    }
    if (valid) {
      ruleForm.value.enclosure = JSON.stringify(ruleForm.value.enclosure);
      add(ruleForm.value).then(() => {
        dialogVisible.value = false
        ElMessage.success('操作成功');
        init()
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}
let download = (url) => {
  const currentUrl = window.location.href;
  const parts = currentUrl.split('/');
  const baseUrl = `${parts[0]}//${parts[2]}`;
  window.open(`${baseUrl}` + '/' + `${url}`)
}
onMounted(async () => {
  await init()
  loading.value = false
})
</script>

<style scoped>
::v-deep .el-select__wrapper {
  height: 100%;
}
::v-deep .el-form--inline .el-form-item {
  margin-right: 0;
}
a {
  text-decoration: none;
}
::v-deep .upload-demo {
  width: 100%;
}
.btn-8 {
  width: 80px;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(
      135deg,
      rgba(31, 112, 247, 1) 0%,
      rgba(85, 155, 254, 1) 100%
    ),
    rgba(31, 112, 247, 1);
  font-size: 14px;
}
::v-deep .el-table thead th {
  background: rgb(248, 249, 252);
}
::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: 700;
  color: rgb(0, 82, 217);
}
.annex {
  width: 350px;
  border-radius: 6px;
  background: rgba(248, 249, 252, 1);
  border: 1px dashed rgba(194, 198, 206, 1);
  padding: 20px;
  box-sizing: border-box;
}
.tit {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(212, 48, 48, 1);
}
.jt {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  height: 20px;
}
</style>