// import request from '@/axios';
import request from "@/util/axios"
export const getList = (current, size, params) => {
  return request({
    url: 'api/blade-team/team/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getCmsList = (current, size, params) => {
  return request({
    url: 'api/blade-team/team/cms-page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: 'api/blade-team/team/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: 'api/blade-team/team/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const teamBatchExitApi = (ids) => {
  return request({
    url: 'api/blade-team/team/teamBatchExit',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: 'api/blade-team/team/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: 'api/blade-team/team/submit',
    method: 'post',
    data: row
  })
}

export const insertTeamApi = (row) => {
  return request({
    url: 'api/blade-team/team/insertTeam',
    method: 'post',
    data: row
  })
}

export const teamExitApi = (row) => {
  return request({
    url: 'api/blade-team/team/teamExit',
    method: 'post',
    data: row
  })
}

/**
 * 文件流返回
 * @param url 接口地址
 * @param params 接口参数
 */
export const exportBlob = (params) => {
  return request({
    url: 'api/blade-team/team/export-team',
    params: params,
    method: 'get',
    responseType: 'blob',
  });
};

