// import request from '@/axios';
import request from "@/util/axios"
export const getList = (current, size, params) => {
  return request({
    url: 'api/blade-worker/worker/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getCmsList = (current, size, params) => {
  return request({
    url: 'api/blade-worker/worker/cms-list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getCmsInOutList = (current, size, params) => {
  return request({
    url: 'api/blade-worker/worker/cms-in-out-list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: 'api/blade-worker/worker/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: 'api/blade-worker/worker/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const workerExitBatchApi = (ids) => {
  return request({
    url: 'api/blade-worker/worker/workerExitBatch',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: 'api/blade-worker/worker/submit',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: 'api/blade-worker/worker/submit',
    method: 'post',
    data: row
  })
}

export const insertWorkerApi = (row) => {
  return request({
    url: 'api/blade-worker/worker/insertWorker',
    method: 'post',
    data: row
  })
}

export const workerExitApi = (row) => {
  return request({
    url: 'api/blade-worker/worker/workerExit',
    method: 'post',
    data: row
  })
}

/**
 * 文件流返回
 * @param url 接口地址
 * @param params 接口参数
 */
export const exportBlob = (params) => {
  return request({
    url: 'api/blade-worker/worker/export-worker',
    params: params,
    method: 'get',
    responseType: 'blob',
  });
};

