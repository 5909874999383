<template>
  <div class="my-orders" v-loading="loading">
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;padding:20px;">
        <div style="display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">身份证号：</div>
          <div>
            <el-input v-model="query.certificatesNum" style="width: 220px;height: 32px;" placeholder='请输入身份证号' />
          </div>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">工人姓名：</div>
          <div>
            <el-input v-model="query.name" style="width: 220px;height: 32px;" placeholder='请输入工人姓名' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">所属班组：</div>
          <div>
            <el-input v-model="query.teamName" style="width: 220px;height: 32px;" placeholder='请输入所属班组' />
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">工人状态：</div>
          <div>
            <el-select v-model="query.activeState" placeholder="请选择班级" size='default' style="width: 220px;height: 32px;">
              <el-option label="进厂" value="1" />
              <el-option label="退场" value="-1" />
            </el-select>
          </div>
        </div>
        <div style="width: 290px;display: flex;align-items: center;flex-direction: row-reverse;margin-top: 20px;">
          <div style="font-size: 14px;color: rgb(32, 35, 50);">
            <el-button type="primary" size="default" :icon="Search" style="width: 78px;height: 32px;" @click="searchList">搜索</el-button>
            <el-button size="default" :icon="RefreshLeft" style="width: 78px;height: 32px;margin-left:10px;" @click="reset">重置</el-button>
          </div>
        </div>
      </div>
      <div class="btn-arr">
        <!-- /api/blade-worker/worker/put-file -->
        <el-upload style="display: flex;" v-model:file-list="fileList" action="/api/blade-worker/worker/put-file" :on-success="successFile1" :headers="headers">
          <el-button type="primary" size="default" class="btn-7">
            <img src="../../static/icon/daoru.png" style="margin-right:5px" alt="" srcset="">
            导入
          </el-button>
        </el-upload>
        <el-button type="primary" size="default" class="btn-7" @click="expertWorker">
          <img src="../../static/icon/daochu.png" style="margin-right:5px" alt="" srcset="">
          导出
        </el-button>
        <el-button type="primary" size="default" class="btn-8" @click="addWorker">
          <img src="../../static/icon/plus.png" style="margin-right:5px" alt="" srcset="">
          新增工人
        </el-button>
        <el-button type="primary" size="default" class="btn-7" @click="batchWorkerExit">
          <img src="../../static/icon/home.png" style="margin-right:5px" alt="" srcset="">
          批量退场
        </el-button>
      </div>
      <div class="box">
        <el-table :data="tableData" style="width: 100%;font-size:14px;" @selection-change="selectionChange">
          <el-table-column type="selection" width="50" />
          <el-table-column label="编号" prop="workerCode" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="姓名" prop="name" width="80" align="center" show-overflow-tooltip />
          <el-table-column label="性别" prop="sex" width="80" align="center" show-overflow-tooltip />
          <el-table-column label="民族" prop="nation" width="80" align="center" show-overflow-tooltip />
          <el-table-column label="证件类型" prop="certificatesType" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="证件号码" prop="certificatesNum" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="发证机关" prop="issuingAuthority" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="有效开始日期" prop="startTime" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="有效结束日期" prop="endTime" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="出生日期" prop="dateOfBirth" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="政治面貌" prop="politicalLandscape" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="文化程度" prop="education" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="婚姻状况" prop="maritalStatus" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="手机号" prop="phone" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="住址" prop="address" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="是否班组长" prop="isLeader" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="所属班组" prop="teamName" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="所属项目" prop="projectName" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="工种" prop="jobType" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="是否重大病史" prop="isMedicalHistory" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="紧急联系人" prop="emergencyContract" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="紧急联系人电话" prop="emergencyContractPhone" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="进场时间" prop="comeInTime" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="退场时间" prop="exitTime" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="开始工作时间" prop="startWorkTime" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="是否加入公会" prop="isUnion" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="加入工会时间" prop="startUnionTime" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="工资卡类别" prop="salaryCardCategory" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="工资银行卡号" prop="salaryCardNum" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="工资卡开户行" prop="openingBank" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="合同签订日期" prop="contractSignDate" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="合同类型" prop="contractType" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="合同起始时间" prop="contractStartTime" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="合同终止时间" prop="contractEndTime" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="工资支付日期(日)" prop="salaryPayTime" width="160" align="center" show-overflow-tooltip />
          <el-table-column label="工资计算方式" prop="salaryCalMethod" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="工资标准(元)" prop="salaryStandards" width="120" align="center" show-overflow-tooltip />
          <el-table-column label="备注" prop="remarks" width="200" align="center" show-overflow-tooltip />
          <el-table-column fixed="right" label="操作" prop="Operations" min-width="120" align="center">
            <template #default="scope">
              <el-button size="default" type='text' @click="workerExit(scope.row)">
                退场
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="demo-pagination-block">
        <el-pagination v-model:current-page="page.currentPage" v-model:page-size="page.pageSize" :page-sizes="[10, 20, 40]" :size="size" :disabled="disabled" layout="total, sizes, prev, pager, next, jumper" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" background />
      </div>
    </div>
    <el-dialog style="margin-top:3vh" v-model="dialogVisible" title="新增工人" width="1050" :before-close="handleClose">
      <el-form ref="ruleFormRef" :inline="true" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon>
        <el-form-item label="编号：" prop="workerCode">
          <el-input v-model="ruleForm.workerCode" disabled style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="姓名：" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入姓名" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="性别：" prop="sex">
          <el-input v-model="ruleForm.sex" placeholder="请输入性别" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="民族：" prop="nation">
          <el-input v-model="ruleForm.nation" type="text" placeholder="请输入民族" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="证件类型：" prop="certificatesType">
          <el-input v-model="ruleForm.certificatesType" type="text" placeholder="请输入证件类型" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="证件号码：" prop="certificatesNum">
          <el-input v-model="ruleForm.certificatesNum" type="text" placeholder="请输入证件号码" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="发证机关：" prop="issuingAuthority">
          <el-input v-model="ruleForm.issuingAuthority" type="text" placeholder="请输入发证机关" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="有效开始日期：" prop="startTime">
          <el-input v-model="ruleForm.startTime" type="text" placeholder="请输入有效开始日期" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="有效结束日期：" prop="endTime">
          <el-input v-model="ruleForm.endTime" type="text" placeholder="请输入有效结束日期" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="出生日期：" prop="dateOfBirth">
          <el-input v-model="ruleForm.dateOfBirth" type="text" placeholder="请输入出生日期" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="政治面貌：" prop="politicalLandscape">
          <el-input v-model="ruleForm.politicalLandscape" type="text" placeholder="请输入政治面貌" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="文化程度：" prop="education">
          <el-input v-model="ruleForm.education" type="text" placeholder="请输入文化程度" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="婚姻状况：" prop="maritalStatus">
          <el-input v-model="ruleForm.maritalStatus" type="text" placeholder="请输入婚姻状况" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="ruleForm.phone" type="text" placeholder="请输入手机号" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="住址：" prop="address">
          <el-input v-model="ruleForm.address" type="text" placeholder="请输入住址" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="是否班组长：" prop="isLeader">
          <el-input v-model="ruleForm.isLeader" type="text" placeholder="请输入是否班组长" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="所属班组：" prop="teamName">
          <el-input v-model="ruleForm.teamName" type="text" placeholder="请输入所属班组" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="所属项目：" prop="projectName">
          <el-input v-model="ruleForm.projectName" type="text" placeholder="请输入所属项目" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="工种：" prop="jobType">
          <el-input v-model="ruleForm.jobType" type="text" placeholder="请输入工种" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="是否重大病史：" prop="isMedicalHistory">
          <el-input v-model="ruleForm.isMedicalHistory" type="text" placeholder="请输入是否重大病史" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="紧急联系人：" prop="emergencyContract">
          <el-input v-model="ruleForm.emergencyContract" type="text" placeholder="请输入紧急联系人" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="紧急联系人电话：" prop="emergencyContractPhone">
          <el-input v-model="ruleForm.emergencyContractPhone" type="text" placeholder="请输入紧急联系人电话" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="进场时间：" prop="comeInTime">
          <el-input v-model="ruleForm.comeInTime" type="text" placeholder="请输入进场时间" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="退场时间：" prop="exitTime">
          <el-input v-model="ruleForm.exitTime" type="text" placeholder="请输入退场时间" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="开始工作时间：" prop="startWorkTime">
          <el-input v-model="ruleForm.startWorkTime" type="text" placeholder="请输入开始工作时间" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="是否加入公会：" prop="isUnion">
          <el-input v-model="ruleForm.isUnion" type="text" placeholder="请输入是否加入公会" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="加入工会时间：" prop="startUnionTime">
          <el-input v-model="ruleForm.startUnionTime" type="text" placeholder="请输入加入工会时间" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="工资卡类别：" prop="salaryCardCategory">
          <el-input v-model="ruleForm.salaryCardCategory" type="text" placeholder="请输入工资卡类别" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="工资银行卡号：" prop="salaryCardNum">
          <el-input v-model="ruleForm.salaryCardNum" type="text" placeholder="请输入工资银行卡号" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="工资卡开户行：" prop="openingBank">
          <el-input v-model="ruleForm.openingBank" type="text" placeholder="请输入工资卡开户行" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="合同签订日期：" prop="contractSignDate">
          <el-input v-model="ruleForm.contractSignDate" type="text" placeholder="请输入合同签订日期" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="合同签订日期：" prop="contractSignDate">
          <el-input v-model="ruleForm.contractSignDate" type="text" placeholder="请输入合同签订日期" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="合同类型：" prop="contractType">
          <el-input v-model="ruleForm.contractType" type="text" placeholder="请输入合同类型" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="合同起始时间：" prop="contractStartTime">
          <el-input v-model="ruleForm.contractStartTime" type="text" placeholder="请输入合同起始时间" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="合同终止时间：" prop="contractEndTime">
          <el-input v-model="ruleForm.contractEndTime" type="text" placeholder="请输入合同终止时间" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="工资支付日期(日)：" prop="salaryPayTime">
          <el-input v-model="ruleForm.salaryPayTime" type="text" placeholder="请输入工资支付日期(日)" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="工资计算方式：" prop="salaryCalMethod">
          <el-input v-model="ruleForm.salaryCalMethod" type="text" placeholder="请输入工资计算方式" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="工资标准(元)：" prop="salaryStandards">
          <el-input v-model="ruleForm.salaryStandards" type="text" placeholder="请输入工资标准(元)" style="min-height:40px;" />
        </el-form-item>
        <el-form-item label="备注：" prop="remarks">
          <el-input v-model="ruleForm.remarks" type="text" placeholder="请输入备注" style="min-height:40px;" />
        </el-form-item>

      </el-form>
      <template #footer>
        <div class="dialog-footer" style="display: flex;justify-content: center;align-items: center">
          <el-button type="primary" @click="SuccessDialogVisible(ruleFormRef)" style="margin-right: 10px;width: 100px;height:40px;">
            确定
          </el-button>
          <el-button @click="resetForm(ruleFormRef)" style="width: 100px;height:40px;">取消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { getCmsList, workerExitApi, workerExitBatchApi, insertWorkerApi } from '@/api/biz/worker';
import { reactive, onMounted, ref } from 'vue';
import { WorkgetCmsList } from '@/api/index.js';
import { ElMessage, ElMessageBox } from 'element-plus'
let tableData = ref([]);
let name = ref("")
let ruleFormRef = ref()
let ruleForm = ref({
  name: '',
  sex: '',
  nation: '',
  certificatesType: '',
  certificatesNum: '',
  issuingAuthority: '',
  startTime: '',
  endTime: '',
  dateOfBirth: '',
  politicalLandscape: '',
  education: '',
  maritalStatus: '',
  phone: '',
  address: '',
  isLeader: '',
  teamName: '',
  projectName: '',
  jobType: '',
  isMedicalHistory: '',
  emergencyContract: '',
  emergencyContractPhone: '',
  comeInTime: '',
  exitTime: '',
  startWorkTime: '',
  isUnion: '',
  startUnionTime: '',
  salaryCardCategory: '',
  salaryCardNum: '',
  openingBank: '',
  contractSignDate: '',
  contractType: '',
  contractStartTime: '',
  contractEndTime: '',
  salaryPayTime: '',
  salaryCalMethod: '',
  salaryStandards: '',
  remarks: '',
})
let rules = ref({
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' },
  ],
  certificatesType: [
    { required: true, message: '请输入证件类型', trigger: 'blur' },
  ],
  certificatesNum: [
    { required: true, message: '请输入证件号码', trigger: 'blur' },
  ], projectName: [
    { required: true, message: '请输入所属项目', trigger: 'blur' },
  ], teamName: [
    { required: true, message: '请输入所属班组', trigger: 'blur' },
  ],
})
let page = ref({
  total: '',
  currentPage: 1,
  pageSize: 10
});
let headers = { "blade-auth": 'bearer ' + localStorage.getItem('tokenuser') }

let loading = ref(true)
let searchIs = ref(false)
let size = ref('default')
let disabled = ref(false)
let dialogVisible = ref(false)
let SuccessDialogVisible = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      insertWorkerApi(ruleForm.value).then(res => {
        console.log(res);
        if (res.data.success) {
          ElMessage.success('新增工人成功！');
          resetForm(ruleFormRef.value)
          onLoad()
        } else {
          ElMessage.error(res.data.msg);
        }
      })
    } else {
      console.log('error submit!', fields);
    }
  });
}
let resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  dialogVisible.value = false
}
let handleClose = () => {
  resetForm(ruleFormRef.value)
}
let handleSizeChange = (val) => {
  page.value.pageSize = val
  if (searchIs.value) {
    onLoad()
  } else {
    name.value = ''
    onLoad()
  }
  window.scrollTo(0, 0)
}
let successFile1 = (response) => {
  if (response.code == 200) {
    onLoad()
  }
}
let handleCurrentChange = (val) => {
  page.value.currentPage = val
  if (searchIs.value) {
    onLoad()
  } else {
    name.value = ''
    onLoad()
  }
  window.scrollTo(0, 0)
}
let query = reactive({
  certificatesNum: '',
  name: '',
  teamName: '',
  activeState: ''
});
let ids = ref([]);
let fileList = ref([]);
let onLoad = () => {
  WorkgetCmsList(page.value.currentPage, page.value.pageSize, query).then(res => {
    tableData.value = res.data.records;
    page.value.total = res.data.total;
  })
}
let uploadFile = () => {
  console.log(111111111);
  // WorkerExitApi(row).then(res => {
  //   if (res.success) {
  //     onLoad()
  //     ElMessage.success('操作成功')
  //   }
  // })
}
let workerExit = (row) => {
  ElMessageBox.confirm('确定退场该工人吗？', '工人退场',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      showCancelbutton: true
    }
  ).then(() => {
    workerExitApi({ id: row.id }).then(res => {
      if (res.code == 200) {
        ElMessage({
          type: 'success',
          message: '退场成功',
        })
        onLoad()
      }
    }).catch(() => {
      return
    })
  })
}
let batchWorkerExit = () => {
  if (ids.value && ids.value != '') {
    ElMessageBox.alert('确定批量退场该工人吗？', '工人退场', {
      confirmButtonText: '确定',
      callback: (action) => {
        workerExitBatchApi(ids.value.toString()).then(res => {
          if (res.code == 200) {
            ElMessage({
              type: 'success',
              message: '批量退场成功',
            })
            onLoad()
          } else {
            ElMessage({
              type: 'warning',
              message: '请稍后再试',
            })
          }
        })
      }
    })
  } else {
    ElMessage({
      type: 'warning',
      message: '请至少选择一位工人',
    })
  }
}
let addWorker = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  if (hours < 10) {
    hours = '0' + hours;
    minutes = '0' + minutes;
  }
  ruleForm.value.workerCode = year + month + day + hours + minutes;
  dialogVisible.value = true
}
let expertWorker = () => {
  window.location.href = '/api/blade-worker/worker/export-worker?Blade-Auth=' +
    'bearer ' + localStorage.getItem('tokenuser')
};
let selectionChange = (newSelection) => {
  if (newSelection.length) {
    let tempIds = ref([]);
    newSelection.forEach(e => tempIds.value.push(e.id));
    ids.value = tempIds.value;
  }
}
let searchList = async () => {
  searchIs.value = true
  await onLoad()
}
let reset = async () => {
  query.certificatesNum = ''
  query.name = ''
  query.teamName = ''
  query.activeState = ''
  await onLoad()
}

onMounted(async () => {
  await onLoad();
  loading.value = false
})

</script>
<style scoped>
.demo-pagination-block {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px;
}

.btn-7 {
  height: 40px;
  border-radius: 4px;
  border: 1px solid rgba(31, 112, 247, 1);
  margin-right: 20px;
  background: #fff;
  color: rgba(31, 112, 247, 1);
  margin-left: 0px !important;
}
.btn-8 {
  width: 100px;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(
      135deg,
      rgba(31, 112, 247, 1) 0%,
      rgba(85, 155, 254, 1) 100%
    ),
    rgba(31, 112, 247, 1);
  margin-left: 0px !important;
}

.btn-arr {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
}
.box {
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  padding: 20px;
  box-sizing: border-box;
}
.my-orders {
  padding: 15px;
  box-sizing: border-box;
}
::v-deep .el-upload-list {
  display: none !important;
}
::v-deep .el-form--inline .el-form-item {
  width: 45%;
  align-items: center;
}
::v-deep .el-dialog__title {
  margin-bottom: 20px;
  font-size: 20px;
  display: block;
  font-weight: 700;
  color: rgb(0, 82, 217);
}
::v-deep .el-dialog__body {
  overflow-y: scroll;
  height: 700px;
}
</style>