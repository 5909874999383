<template>
  <div class="my-orders" v-loading="loading">
    <div style="display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;padding:20px;">
      <div style="display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">工人姓名：</div>
        <div>
          <el-input v-model="query.name" style="width: 220px;height: 32px;" placeholder='请输入工人姓名' />
        </div>
      </div>
      <div style="width: 290px;display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">所属项目：</div>
        <div>
          <el-input v-model="query.teamName" style="width: 220px;height: 32px;" placeholder='请输入所属项目' />
        </div>
      </div>
      <div style="width: 290px;display: flex;align-items: center;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">所属班组：</div>
        <div>
          <el-input v-model="query.projectName" style="width: 220px;height: 32px;" placeholder='请输入所属班组' />
        </div>
      </div>
      <div style="width: 290px;display: flex;align-items: center;margin-top: 20px;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">退场时间：</div>
        <div>
          <el-date-picker v-model="query.exitTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD HH:mm:ss" type="date" placeholder="请选择退场日期" size="default" />
        </div>
      </div>
      <div style="width: 290px;display: flex;align-items: center;flex-direction: row-reverse;margin-top: 20px;">
        <div style="font-size: 14px;color: rgb(32, 35, 50);">
          <el-button type="primary" size="default" :icon="Search" style="width: 78px;height: 32px;" @click="searchList">搜索</el-button>
          <el-button size="default" :icon="RefreshLeft" style="width: 78px;height: 32px;margin-left:10px;" @click="reset">重置</el-button>
        </div>
      </div>
    </div>
    <div class="box">
      <el-table :data="tableData" style="width: 100%;font-size:14px;">
        <el-table-column label="编号" prop="workerCode" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="姓名" prop="name" width="80" align="center" show-overflow-tooltip />
        <el-table-column label="性别" prop="sex" width="80" align="center" show-overflow-tooltip />
        <el-table-column label="民族" prop="nation" width="80" align="center" show-overflow-tooltip />
        <el-table-column label="证件类型" prop="certificatesType" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="证件号码" prop="certificatesNum" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="发证机关" prop="issuingAuthority" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="有效开始日期" prop="startTime" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="有效结束日期" prop="endTime" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="出生日期" prop="dateOfBirth" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="政治面貌" prop="politicalLandscape" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="文化程度" prop="education" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="婚姻状况" prop="maritalStatus" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="手机号" prop="phone" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="住址" prop="address" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="是否班组长" prop="isLeader" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="所属班组" prop="teamName" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="所属项目" prop="projectName" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="工种" prop="jobType" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="是否重大病史" prop="isMedicalHistory" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="紧急联系人" prop="emergencyContract" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="紧急联系人电话" prop="emergencyContractPhone" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="进场时间" prop="comeInTime" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="退场时间" prop="exitTime" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="开始工作时间" prop="startWorkTime" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="是否加入公会" prop="isUnion" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="加入工会时间" prop="startUnionTime" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="工资卡类别" prop="salaryCardCategory" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="工资银行卡号" prop="salaryCardNum" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="工资卡开户行" prop="openingBank" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="合同签订日期" prop="contractSignDate" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="合同类型" prop="contractType" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="合同起始时间" prop="contractStartTime" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="合同终止时间" prop="contractEndTime" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="工资支付日期(日)" prop="salaryPayTime" width="160" align="center" show-overflow-tooltip />
        <el-table-column label="工资计算方式" prop="salaryCalMethod" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="工资标准(元)" prop="salaryStandards" width="120" align="center" show-overflow-tooltip />
        <el-table-column label="备注" prop="remarks" width="200" align="center" show-overflow-tooltip />
      </el-table>
    </div>
    <div class="demo-pagination-block">
      <el-pagination v-model:current-page="page.currentPage" v-model:page-size="page.pageSize" :page-sizes="[10, 20, 40]" :size="size" :disabled="disabled" layout="total, sizes, prev, pager, next, jumper" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" background />
    </div>
  </div>
</template>
<script setup>
import { getCmsInOutList } from '@/api/biz/worker';
import { reactive, onMounted, ref } from 'vue';
let tableData = ref([]);
let loading = ref(true)
let page = ref({
  total: '',
  currentPage: 1,
  pageSize: 10
});
let searchIs = ref(false)
let size = ref('default')
let disabled = ref(false)
let query = reactive({
  name: null,
  teamName: null,
  projectName: null,
  exitTime: null
});
let onLoad = () => {
  getCmsInOutList(page.value.currentPage, page.value.pageSize, query).then(res => {
    tableData.value = res.data.records;
    page.value.total = res.data.total;
  })
}
let searchList = async () => {
  searchIs.value = true
  await onLoad()
}
let reset = async () => {
  query.projectName = null
  query.name = null
  query.teamName = null
  query.exitTime = null
  await onLoad()
}
let handleSizeChange = (val) => {
  page.value.pageSize = val
  if (searchIs.value) {
    onLoad()
  } else {
    query.projectName = null
    query.name = null
    query.teamName = null
    query.exitTime = null
    onLoad()
  }
  window.scrollTo(0, 0)
}
let handleCurrentChange = (val) => {
  page.value.currentPage = val
  if (searchIs.value) {
    onLoad()
  } else {
    query.projectName = null
    query.name = null
    query.teamName = null
    query.exitTime = null
    onLoad()
  }
  window.scrollTo(0, 0)
}
onMounted(async () => {
  await onLoad();
  loading.value = false
})
</script>
<style scoped>
.demo-pagination-block {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px;
}

.box {
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  padding: 20px;
  box-sizing: border-box;
}
.my-orders {
  padding: 15px;
  box-sizing: border-box;
}
</style>