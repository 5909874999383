<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div class="personalcenter">
      <div class="w1300 spaceBetween">
        <div style="display: flex;flex-direction: column;margin-top: 40px;">
          <div class="avatar-box">
            <div class="avatar-img"><img src="./../static/头像.png" alt=""></div>
            <div class="avatar-name">{{userObject.real_name}}</div>
            <div class="avatar-btn" @click="Quit">
              <img src="./../static/exit.png" alt="" style="width: 10px;height:10px;margin-right:5px;">
              <div>退出登录</div>
            </div>
          </div>
          <div class="tree-box">
            <div class="tree-title" :class="{active:active==item.id}" @click="jumpActive(item.id,item.num)" v-for="item in treeArr" :key="item.id">{{item.name}}</div>
          </div>
        </div>
        <div class="tree-content">
          <MyMessage v-if="active==1" />
          <MyOrders v-if="active==2" />
          <ContractManagement v-if="active==3" />
          <ShiftManagement v-if="active==4" />
          <WorkerManagement v-if="active==5" />
          <WorkersEnterAndExit v-if="active==6" />
          <EnterpriseInformationManagement v-if="active==7" />
          <MyletterOfGuarantee v-if="active==8" />
          <PostwarrantyInvestigation v-if="active==9" />
          <CashDepositGuarantee v-if="active==10" />
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import ContractManagement from "./personalCenterSubassembly/ContractManagement.vue";
import EnterpriseInformationManagement from "./personalCenterSubassembly/EnterpriseInformationManagement.vue";
import MyMessage from "./personalCenterSubassembly/MyMessage.vue";
import MyOrders from "./personalCenterSubassembly/MyOrders.vue";
import ShiftManagement from "./personalCenterSubassembly/ShiftManagement.vue";
import WorkerManagement from "./personalCenterSubassembly/WorkerManagement.vue";
import WorkersEnterAndExit from "./personalCenterSubassembly/WorkersEnterAndExit.vue";
import MyletterOfGuarantee from "./personalCenterSubassembly/MyletterOfGuarantee.vue";
import PostwarrantyInvestigation from "./personalCenterSubassembly/PostwarrantyInvestigation.vue";
import CashDepositGuarantee from "./personalCenterSubassembly/CashDepositGuarantee.vue";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from 'element-plus'
let router = useRouter()
let active = ref(null)
let userObject = ref({})
let treeArr = ref([
  { id: 1, name: '我的消息' },
  { id: 2, name: '我的订单' },
  { id: 8, name: '我的保函' },
  { id: 10, name: '现金缴存保函' },
  { id: 9, name: '保后协查' },
  { id: 3, name: '合同管理', num: 6 },
  { id: 4, name: '班组管理', num: 7 },
  { id: 5, name: '工人管理', num: 8 },
  { id: 6, name: '工人进退场', num: 9 },
  { id: 7, name: '企业信息管理' },
])
let jumpActive = (item, num) => {
  if (item != 7) {
    active.value = item
    localStorage.setItem('active', item);
    localStorage.setItem('activeBanner', num)
    window.scrollTo(0, 0)
    location.reload(true)
  } else {
    router.push({ path: '/enterpriseInformationManagement' })
  }
}
let Quit = () => {
  ElMessageBox.confirm('确定要退出登录吗？', '退出登录', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    confirmButtonClass: 'sure',
    cancelButtonClass: 'sure',
    type: 'warning'
  }).then(() => {
    ElMessage({
      type: 'success',
      message: '退出成功!'
    })
    localStorage.removeItem('token');
    localStorage.removeItem('tokenuser');
    localStorage.removeItem('Time');
    localStorage.setItem('activeBanner', 1)
    router.push("/")
    location.reload(true)
  }).catch(() => {
    return
  })
}
onMounted(() => {
  active.value = localStorage.getItem('active') ? localStorage.getItem('active') : 1;
  userObject.value = JSON.parse(localStorage.getItem('token')) ? JSON.parse(localStorage.getItem('token')) : ''
})
</script>
<style>
/* .sure {
  width: 80px;
  height: 32px;
} */
</style>
<style scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
}
.personalcenter {
  width: 100%;
  min-height: 380px;
  background: url("./../static/banner-pub.png") no-repeat center top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
.avatar-box {
  width: 220px;
  height: 286px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
  box-sizing: border-box;
}
.tree-box {
  width: 220px;
  /* height: 452px; */
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  margin-top: 20px;
  padding: 45px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.avatar-img {
  width: 120px;
  height: 120px;
  background: rgba(204, 204, 204, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  overflow: hidden;
}
.avatar-name {
  font-size: 16px;
  font-weight: 400;
  color: rgba(31, 112, 247, 1);
}
.avatar-btn {
  width: 84px;
  height: 32px;
  border-radius: 4px;
  background: linear-gradient(
      135deg,
      rgba(31, 112, 247, 1) 0%,
      rgba(85, 155, 254, 1) 100%
    ),
    rgba(0, 82, 217, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
.tree-title {
  height: 56px;
  width: 100%;
  font-size: 16px;
  color: rgba(31, 112, 247, 1);
  line-height: 56px;
  cursor: pointer;
  text-indent: 4.5em;
}
.active {
  background: rgb(243, 246, 255);
  border-left: 4px solid rgb(31, 112, 247);
}
.tree-content {
  width: 1060px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  margin-top: 40px;
}
</style>
