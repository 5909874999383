<template>
  <div class="container" v-loading="loading">
    <div>
      <div class="box">
        <div class="item" v-for="i in MessageList" :key="i.id">
          <div class="item-top">
            <div class="item-top-left">通知</div>
            <div class="time">{{i.releaseTime}}</div>
          </div>
          <div class="desc">{{i.title}}</div>
        </div>
      </div>
      <div class="demo-pagination-block">
        <el-pagination v-model:current-page="page.currentPage" v-model:page-size="page.pageSize" :page-sizes="[10, 20, 40]" :size="size" :disabled="disabled" layout="total, sizes, prev, pager, next, jumper" :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" background />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { Mesagelist } from "@/api/index.js"
let MessageList = ref()
let loading = ref(true)
let page = ref({
  total: '',
  currentPage: 1,
  pageSize: 10
});
let size = ref('default')
let disabled = ref(false)
let handleSizeChange = async (val) => {
  page.value.pageSize = val
  await mesageList()
  window.scrollTo(0, 0)
}
let handleCurrentChange = async (val) => {
  page.value.currentPage = val
  await mesageList()
  window.scrollTo(0, 0)
}
let mesageList = async () => {
  await Mesagelist(page.value.currentPage, page.value.pageSize).then(res => {
    MessageList.value = res.data.records
    page.value.total = res.data.total
  })
}
onMounted(async () => {
  await mesageList()
  loading.value = false
})
</script>
<style scoped>
.demo-pagination-block {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px 0;
  margin-top: 30px;
}
.container {
  padding: 15px;
  box-sizing: border-box;
}
.box {
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
}
.item {
  height: 100px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(243, 246, 255, 1);
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.item-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.item-top-left {
  height: 26px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26px;
  color: rgba(31, 112, 247, 1);
  text-align: left;
}
.red {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: rgba(212, 48, 48, 1);
  border-radius: 50%;
  margin-right: 10px;
}
.time {
  height: 26px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26px;
  color: rgba(166, 166, 166, 1);
  text-align: left;
}
.desc {
  height: 26px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 26px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}
</style>